<template>
  <div>
    <v-container>
      <v-layout row wrap justify-space-between class="mb-2">
        <v-flex xs6 sm4 md4 lg4 class="mb-1">
          <v-btn
            :color="$store.state.secondaryColor"
            outlined
            small
            @click="viewPurchaseOrders"
          >
            <v-icon small left>mdi-arrow-left</v-icon>
            <span class="caption">{{ $t("back") }}</span>
          </v-btn>
        </v-flex>
      </v-layout>
      <!-- on successful upload to firebase -->
      <v-snackbar v-model="snackbarUpload" top centered>
        {{ $t("your purchase order has been recorded successfully") }}!
        <template v-slot:action="{ attrs }">
          <v-btn
            color="orange darken-3"
            text
            v-bind="attrs"
            @click="snackbarUpload = false"
            :timeout="timeout"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <v-form ref="supplier">
        <v-layout row wrap justify-space-between class="mt-2">
          <v-flex xs12 sm4 md4 lg4 class="px-1">
            <span class="caption font-weight-black text-capitalize"
              >{{ $t("suppliers") }}*
            </span>

            <v-autocomplete
              v-model="purchaseOrder.supplierId"
              :items="suppliers"
              item-text="name"
              item-value="id"
              dense
              outlined
              @change="setSupplierDetails(purchaseOrder.supplierId)"
              :rules="rules.emptyField"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 sm4 md4 lg4 class="px-1">
            <span class="caption font-weight-black text-capitalize"
              >{{ $t("account") }}*
              <span>
                <v-btn
                  height="5"
                  small
                  text
                  class="blue--text text-lowercase text-decoration-underline"
                  @click="newAccount"
                >
                  {{ $t("new Account") }}</v-btn
                >
              </span>
            </span>

            <v-autocomplete
              v-model="purchaseOrder.supplierId"
              :items="accounts"
              item-text="accountName"
              item-value="id"
              dense
              outlined
              @change="setSupplierDetails(purchaseOrder.supplierId)"
              :rules="rules.emptyField"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 sm4 md4 lg4 class="px-1">
            <span class="caption font-weight-black text-capitalize"
              >{{ $t("purchase order number") }}*
            </span>
            <v-text-field
              outlined
              dense
              v-model="purchaseOrder.orderNo"
              :rules="rules.emptyField"
              readonly
            ></v-text-field>

            <span class="caption font-weight-black font-italic">{{
              purchaseOrder.dateTime
            }}</span>
          </v-flex>
        </v-layout>
      </v-form>
      <!-- ######## -->
      <v-layout row wrap class="mt-4">
        <v-flex xs12 sm12 md12 lg12>
          <!-- ############ Add product section -->
          <v-form ref="poProducts" class="mt-4">
            <!-- ############# -->
            <v-simple-table>
              <template v-slot:default>
                <thead class="mb-2">
                  <tr class="" style="background-color: #37474f">
                    <th class="text-left">
                      <span
                        class="caption font-weight-black text-capitalize"
                        style="color: white"
                        >{{ $t("product name") }}*
                      </span>
                    </th>

                    <th class="text-left">
                      <span
                        class="caption font-weight-black text-capitalize"
                        style="color: white"
                        >{{ $t("unit price") }}*
                      </span>
                    </th>

                    <th class="text-left">
                      <span
                        class="caption font-weight-black text-capitalize"
                        style="color: white"
                        >{{ $t("quantity") }}*
                      </span>
                    </th>

                    <th class="text-left">
                      <span
                        class="caption font-weight-black text-capitalize"
                        style="color: white"
                        >{{ $t("total") }}*
                      </span>
                    </th>
                    <th style="color: white">
                      <span class="caption font-weight-black text-capitalize">
                        {{ $t("action") }}
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="py-3">
                      <v-autocomplete
                        v-model="productDetails.barcodeNo"
                        :items="products"
                        item-text="name"
                        item-value="id"
                        dense
                        outlined
                        @change="setProductDetails(productDetails.barcodeNo)"
                        :rules="rules.emptyField"
                      ></v-autocomplete>
                    </td>

                    <td>
                      <v-text-field
                        v-model.number="productDetails.unitPrice"
                        outlined
                        dense
                        @keypress="digits"
                        @input="calcTotalPrice"
                        :rules="rules.emptyField"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-text-field
                        v-model.number="productDetails.quantity"
                        outlined
                        dense
                        @keypress="digits"
                        @input="calcTotalPrice"
                        :rules="rules.emptyField"
                      ></v-text-field>
                    </td>
                    <td class="">
                      <v-text-field
                        v-model.number="productDetails.total"
                        outlined
                        dense
                        readonly
                        @keypress="digits"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-btn
                        small
                        dark
                        color="success"
                        @click="saveProduct"
                        class="mb-5"
                      >
                        <v-icon small>mdi-plus</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-form>
        </v-flex>

        <v-flex xs12 sm12 md12 lg12 class="mt-2">
          <v-simple-table fixed-header>
            <template v-slot:default>
              <caption class="font-weight-light text-uppercase">
                {{
                  $t("products list")
                }}
              </caption>
              <thead>
                <tr>
                  <th class="text-uppercase text-left font-weight-medium">
                    {{ $t("item") }} #
                  </th>
                  <th class="text-uppercase text-left font-weight-medium">
                    {{ $t("qty") }}
                  </th>
                  <th class="text-uppercase text-left font-weight-medium">
                    {{ $t("unit price") }}
                  </th>
                  <th class="text-uppercase text-left font-weight-medium">
                    {{ $t("total") }}
                  </th>
                  <th class="text-uppercase text-left font-weight-medium">
                    {{ $t("actions") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in productsDetailsArray" :key="i">
                  <td>{{ item.barcodeNo }}</td>
                  <td>{{ item.quantity }}</td>
                  <td>{{ item.unitPrice }}</td>
                  <td>{{ item.total }}</td>
                  <td>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          small
                          left
                          v-bind="attrs"
                          v-on="on"
                          :color="$store.state.primaryColor"
                          @click="removeSelectedProduct(item.barcodeNo)"
                          >delete</v-icon
                        >
                      </template>
                      <span>{{ $t("delete") }}</span>
                    </v-tooltip>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-flex>
      </v-layout>
      <v-alert class="gradients mt-1" dark icon="mdi-calendar" dense>
        {{ $t("delivery date") }} : {{ purchaseOrder.deliveryDate }}</v-alert
      >
      <v-layout row wrap justify-space-between class="mt-2">
        <v-spacer></v-spacer>

        <v-flex xs12 sm6 md4 lg4 class="text-right px-2 mb-5">
          <!-- ########Additional costs ###### -->
          <v-layout row wrap>
            <v-flex xs12 sm6 md6 lg6 class="">
              <span class="body-1 font-weight-black text-capitalize">
                {{ $t("subTotal") }}
              </span>
            </v-flex>
            <v-flex xs12 sm6 md6 lg6 class="px-4">
              <span class="body-2 font-weight-black text-capitalize"
                >{{ purchaseOrder.subtotal }}
              </span>
            </v-flex>
            <v-flex xs12 sm6 md6 lg6 class="">
              <span class="body-2 font-weight-black text-capitalize"
                ><v-icon>mdi-plus</v-icon> {{ $t("sales tax") }}
              </span>
            </v-flex>
            <v-flex xs12 sm6 md6 lg6 class="px-4">
              <span class="body-2 font-weight-black text-capitalize"
                >{{ purchaseOrder.salesTax }}
              </span>
            </v-flex>

            <v-flex xs12 sm6 md6 lg6 class="">
              <span class="body-2 font-weight-black text-capitalize"
                ><v-icon>mdi-plus</v-icon>{{ $t("shipping costs") }}
              </span>
            </v-flex>
            <v-flex xs12 sm6 md6 lg6 class="px-4">
              <span class="body-2 font-weight-black text-capitalize"
                >{{ purchaseOrder.shippingCosts }}
              </span>
            </v-flex>
            <v-flex xs12 sm6 md6 lg6 class="">
              <span class="body-2 font-weight-black text-capitalize"
                ><v-icon>mdi-plus</v-icon>{{ $t("other costs") }}
              </span>
            </v-flex>
            <v-flex xs12 sm6 md6 lg6 class="px-4">
              <span class="body-2 font-weight-black text-capitalize"
                >{{ purchaseOrder.otherCosts }}
              </span>
            </v-flex>
            <v-flex xs12 sm6 md6 lg6 class="">
              <span class="body-2 font-weight-black text-capitalize"
                ><v-icon>mdi-minus</v-icon>{{ $t("discount") }}
              </span>
            </v-flex>
            <v-flex xs12 sm6 md6 lg6 class="px-4">
              <span class="body-2 font-weight-black text-capitalize"
                >{{ purchaseOrder.discount }}
              </span>
            </v-flex>

            <v-flex xs12 sm6 md6 lg6 class="">
              <span class="body-1 font-weight-black text-capitalize">
                {{ $t("total") }}
              </span>
            </v-flex>
            <v-flex xs12 sm6 md6 lg6 class="px-4">
              <span class="body-2 font-weight-black text-capitalize"
                >{{ currencyFormat(purchaseOrder.total) }}
              </span>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <!-- ###########Bottom section######## -->

      <v-layout row wrap justify-space-between class="mt-4">
        <v-spacer></v-spacer>
        <v-flex xs12 sm8 md8 lg6 class="px-2">
          <v-layout row wrap justify-space-between>
            <v-flex xs12 sm4 md4 lg3 class="text-right px-2 py-1">
              <v-btn
                outlined
                :color="$store.state.secondaryColor"
                @click="clearForm"
              >
                <v-icon small left>delete</v-icon>
                {{ $t("clear") }}
              </v-btn>
            </v-flex>
            <v-flex xs12 sm3 md4 lg3 class="text-right px-2 py-1">
              <v-btn
                outlined
                :color="$store.state.primaryColor"
                @click="formPreview()"
                :disabled="!productsDetailsArray.length"
              >
                <v-icon small left>print</v-icon>
                {{ $t("form preview") }}
              </v-btn>
            </v-flex>
            <v-flex xs12 sm5 md4 lg3 class="text-right pr-2 py-1">
              <v-btn
                :loading="saveLoading"
                outlined
                :color="$store.state.primaryColor"
                @click="uploadData"
                :disabled="!productsDetailsArray.length"
              >
                <v-icon small left>save</v-icon>
                {{ $t("save") }}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-dialog v-model="purchaseDialog" fullscreen persistent>
        <purchaseOrderForm
          :supplier="supplier"
          :business="business"
          :purchaseOrder="purchaseOrder"
          :orderedProducts="orderedProducts"
          @purchaseDialog="purchaseDialog = false"
        ></purchaseOrderForm>
      </v-dialog>
      <!-- ############# -->

      <v-snackbar
        v-model="snackErrorConfig"
        top
        center
        color="red lighten-5 error--text text--darken-3"
        :timeout="4000"
      >
        <span
          >{{
            $t("error fetching data. please refresh the page and proceed")
          }}.</span
        >
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="error darken-3"
            @click="snackErrorConfig = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar
        v-model="snackClear"
        bottom
        right
        color="success lighten-5 success--text text--darken-3"
        :timeout="4000"
      >
        <span>{{ $t("form cleared") }}</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="success darken-3"
            @click="snackClear = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import db from "@/plugins/fb";
import { addDays } from "date-fns";

import purchaseOrderForm from "@/components/templates/PurchaseOrderForm";

export default {
  components: {
    purchaseOrderForm,
  },
  data: () => ({
    e1: 1,
    snackbarAddProduct: false,
    snackClear: false,
    snackLoading: false,
    snackErrorConfig: false,
    snackbarUpload: false,

    purchaseDialog: false,
    timeout: 2000,
    dialog: false,
    saveLoading: false,
    // to upload
    purchaseOrder: {
      businessId: "",
      orderNo: "",
      dateTime: new Date(),
      products: [], //barcodeNo ,description,quantity, unitPrice, total
      supplierId: "",
      shipTo: "", //same as businessId for now...may include warehouses in future
      shippingTerms: "",
      shippingMethod: "",
      deliveryDate: "",
      comments: "",
      discount: 0,
      salesTax: 0,

      shippingCosts: 0,
      otherCosts: 0,
      subtotal: 0,
      total: 0,
      isActive: true,
    },

    items: [{ text: "Save", icon: "save", action: "uploadData" }],

    accounts: [],
    suppliers: [], //suppliers list
    products: [], //product list
    business: "", //current business & company info

    supplier: {
      name: "",
      address: "",
      email: "",
      phone: "",
    },

    productDetails: {
      barcodeNo: "",
      description: "",
      quantity: "",
      unitPrice: "",
      total: "",
    },
    productsDetailsArray: [],

    orderedProducts: [],
    stepName: "Order details",
  }),

  computed: {
    selected() {
      if (this.$store.getters.getCurrentBusiness) {
        return this.$store.getters.getCurrentBusiness;
      } else {
        return this.$store.getters.getCurrentBusinessTemp;
      }
    },

    address() {
      return `${this.business.address.building}, ${this.business.address.street},
           ${this.business.address.city}, ${this.business.address.country}`;
    },

    businessOwner() {
      return this.$store.state.currentUser;
    },
    rules() {
      return {
        email: [
          (v) => !!v || this.$t("e-mail is required"),
          (v) => /.+@.+\..+/.test(v) || this.$t("please enter a valid email"),
        ],
        emptyField: [(v) => !!v || this.$t("please fill out this field")],
      };
    },
  },

  created() {
    this.getBusiness();
    this.getSuppliers();
    this.getAccounts();
    this.generateOrderNumber();
    this.generateDate();
  },

  methods: {
    //attach the selected currency to money
    currencyFormat(val) {
      return this.$store.state.currency.symbol + " " + Number(val).toFixed(2);
    },
    //get products where where supplier matches
    getProducts(id) {
      db.collection("products")
        .where("supplierID", "==", id)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.products.push({
              id: doc.id,
              ...doc.data(),
            });
          });
        })
        .catch(() => {
          this.snackErrorConfig = true;
        });
    },

    getBusiness() {
      let allBusinesses = this.$store.getters.getBusinesses;
      this.business = allBusinesses.find((x) => x.id == this.selected);
    },

    getSuppliers() {
      if (this.selected) {
        db.collection("suppliers")
          .where("businessID", "==", this.selected)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.suppliers.push({
                id: doc.id,
                ...doc.data(),
              });
            });
          });
      } else {
        db.collection("suppliers")
          .where("businessID", "==", this.$store.gettersgetCurrentBusinessTemp)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.suppliers.push({
                id: doc.id,
                ...doc.data(),
              });
            });
          });
      }
    },

    getAccounts() {
      if (this.selected) {
        db.collection("accounts")
          .where("businessId", "==", this.selected)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.accounts.push({
                id: doc.id,
                ...doc.data(),
              });
            });
          });
      } else {
        db.collection("accounts")
          .where("businessId", "==", this.$store.gettersgetCurrentBusinessTemp)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.accounts.push({
                id: doc.id,
                ...doc.data(),
              });
            });
          });
      }
    },

    setProductDetails(id) {
      let product = this.products.find((x) => x.id === id);
      this.productDetails.unitPrice = product.price.buying;

      this.productDetails.name = product.name;

      this.productDetails.description =
        product.description.type +
        "," +
        product.description.color +
        "," +
        product.description.size +
        "," +
        product.description.brand +
        "," +
        product.description.model +
        ",";
    },

    setSupplierDetails(id) {
      let supplier = this.suppliers.find((x) => x.id === id);
      //get items belonging to selected supplier
      this.getProducts(supplier.id);
      this.supplier.name = supplier.name;
      this.supplier.email = supplier.email;
      this.supplier.phone = supplier.phone;
      this.supplier.address =
        supplier.address.building +
        " , " +
        supplier.address.city +
        " , " +
        supplier.address.country;

      //shipping information from supplier

      this.supplier.salesTax = supplier.salesTax;

      this.supplier.discount = supplier.discount;

      this.purchaseOrder.otherCosts = supplier.otherCosts;

      let numberOfDaysToDeliver = supplier.daysAfterOrder;

      this.purchaseOrder.deliveryDate = addDays(
        new Date(),
        numberOfDaysToDeliver
      );

      this.purchaseOrder.shippingCosts = supplier.shippingCosts;
      this.purchaseOrder.shippingMethod = supplier.shippingMethod;
      this.purchaseOrder.shippingTerms = supplier.shippingTerms;
    },

    calcTotalPrice() {
      this.productDetails.total =
        Number(this.productDetails.quantity) *
        Number(this.productDetails.unitPrice);
    },

    calcSubtotal() {
      let allTotals = this.productsDetailsArray.map((item) => item.total);
      let subtotal = allTotals.reduce((prev, next) => prev + next);
      this.purchaseOrder.subtotal = subtotal;
    },

    calcPercent(percentage, num) {
      return Math.round((percentage / 100) * num);
    },

    calcTotal() {
      if (this.productsDetailsArray.length > 0) {
        // get subtotal
        this.calcSubtotal();

        let salesTax =
          this.supplier.salesTax != 0
            ? this.calcPercent(
                this.supplier.salesTax,
                this.purchaseOrder.subtotal
              )
            : 0;
        this.purchaseOrder.salesTax = salesTax;

        let discount =
          this.supplier.discount != 0
            ? this.calcPercent(
                this.supplier.discount,
                this.purchaseOrder.subtotal
              )
            : 0;

        this.purchaseOrder.discount = discount;

        let otherCosts =
          this.purchaseOrder.otherCosts != 0
            ? this.purchaseOrder.otherCosts
            : 0;

        let shippingCosts =
          this.purchaseOrder.shippingCosts != 0
            ? this.purchaseOrder.shippingCosts
            : 0;

        // sales tax

        let total =
          this.purchaseOrder.subtotal +
          Number(salesTax) +
          Number(otherCosts) +
          Number(shippingCosts) -
          Number(discount);
        this.purchaseOrder.total = total;
      } else {
        this.purchaseOrder = [];
        this.purchaseOrder.subtotal = 0;
        this.purchaseOrder.total = 0;
        this.generateOrderNumber();
      }
    },

    saveProduct() {
      if (this.$refs.supplier.validate() && this.$refs.poProducts.validate()) {
        this.productsDetailsArray.push({ ...this.productDetails });

        this.calcTotal();
        this.$refs.poProducts.reset();
      }
    },
    clearForm() {
      this.$refs.supplier.reset();
      this.$refs.poProducts.reset();

      this.generateOrderNumber();
      this.generateDate();

      this.snackClear = true;
    },

    // saveOrderForm() {
    //   if (this.$refs.poProducts.validate()) {
    //     this.orderedProducts = this.productsDetailsArray;

    //     this.dialog = false;
    //   } else {
    //     alert("Please fill out all required fields");
    //   }
    // },

    removeSelectedProduct(val) {
      let productIndex = this.productsDetailsArray
        .map((item) => item.barcodeNo)
        .indexOf(val);

      this.productsDetailsArray.splice(productIndex, 1);
      this.calcTotal();
    },

    uploadData() {
      //
      this.orderedProducts = this.productsDetailsArray;
      this.saveLoading = true;
      this.purchaseOrder.businessId = this.selected;
      this.purchaseOrder.shipTo = this.selected;
      this.purchaseOrder.products = this.productsDetailsArray;

      db.collection("purchaseOrders")
        .add(this.purchaseOrder)
        .then(() => {
          this.loading = false;
          this.snackbarUpload = true;
          setTimeout(() => this.goBack(), 2000);
        });
    },

    goBack() {
      this.$router.push("/stock/purchase-order");
    },
    //redirect back to po list
    viewPurchaseOrders() {
      this.$router.push({ name: "ViewPurchaseOrders" });
    },

    continueStepper() {
      if (this.e1 == 1 && this.$refs.step1.validate()) {
        this.stepName = "Product details";
        this.e1 = 2;
      } else if (this.e1 == 2 && this.productsDetailsArray.length) {
        this.stepName = "Terms and cost";
        this.e1 = 3;
      } else if (this.e1 == 2 && !this.productsDetailsArray.length) {
        this.snackbarAddProduct = true;
      }
    },

    // demoStep1() {
    //   this.purchaseOrder.supplierId = this.suppliers[0].id;
    // },

    digits(event) {
      let keyCode = event.keyCode ? event.keyCode : event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 43) {
        // 46 is dot 43 is +
        event.preventDefault();
      }
    },

    formPreview() {
      this.orderedProducts = this.productsDetailsArray;

      this.purchaseOrder.businessId = this.selected;
      this.purchaseOrder.shipTo = this.selected;
      this.purchaseOrder.products = this.productsDetailsArray;

      this.purchaseDialog = true;
    },

    generateOrderNumber() {
      const zeroPad = (num, places) => String(num).padStart(places, "0");
      db.collection("purchaseOrders")
        .get()
        .then((querySnapshot) => {
          let series = querySnapshot.size + 1;
          this.purchaseOrder.orderNo = zeroPad(series, 4);
        });
    },
    //route to new account page
    newAccount() {
      this.$router.push({
        name: "NewAccount",
      });
    },
    generateDate() {
      this.purchaseOrder.dateTime = new Date();
    },
  },
};
</script>

<style scoped>
.input-text {
  background-color: white;
  border-bottom: 1px solid black;
  padding: 2px;
}
.header {
  background-color: #d7e7ec;
  border-left: 5px solid #038cbe;
  padding-left: 2px;
}
</style>
