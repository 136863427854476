<template>
  <div>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="goBack()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t("purchase order preview") }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            dark
            class="gradients white--text mx-1 py-1"
            style="border-radius: 5px"
            text
            @click="printPage()"
          >
            <v-icon left>print</v-icon>
            {{ $t("print") }}
          </v-btn>
          <v-btn
            :loading="saveLoading"
            dark
            class="gradients white--text mx-1 py-1"
            style="border-radius: 5px"
            text
            @click="uploadData()"
          >
            <v-icon left>save</v-icon>
            {{ $t("save") }}
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <!-- on successful upload to firebase -->
      <v-snackbar v-model="snackbarUpload" top centered>
        {{ $t("your purchase order has been recorded successfully") }}!
        <template v-slot:action="{ attrs }">
          <v-btn
            color="orange darken-3"
            text
            v-bind="attrs"
            @click="snackbarUpload = false"
            :timeout="timeout"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <!-- Purchase order document layout -->
      <div class="body" id="print">
        <div class="container">
          <div class="row right">
            <div class="column-quarter">&nbsp;</div>
            <div class="column-quarter">&nbsp;</div>
            <div class="column-quarter">&nbsp;</div>
            <div class="column-quarter">
              <span class="bold-text title">{{ $t("purchase order") }}</span>
            </div>
          </div>
          <!-- ######Picture date po number row####### -->

          <div class="row">
            <div class="column-half left">
              <span class="bold-text"
                >{{ $t("company name") }}:
                <span class="light-text">{{
                  business.businessName
                }}</span></span
              ><br />
              <span class="bold-text"
                >{{ $t("address") }} :
                <span class="light-text"
                  >{{ business.address.building }} ,{{
                    business.address.city
                  }}
                  ,{{ business.address.country }}</span
                ></span
              ><br />
              <span class="bold-text"
                >{{ $t("telephone") }}:
                <span class="light-text">{{ business.phone }}</span></span
              ><br />
              <span class="bold-text"
                >{{ $t("email") }}:
                <span class="light-text">{{ business.email }}</span></span
              ><br />
              <span class="bold-text"
                >{{ $t("website") }}:
                <span class="light-text">{{ business.website }}</span></span
              >
            </div>

            <div class="column-half right" style="font-size: 13px">
              <span class="bold-text"
                >{{ $t("date") }}:
                <span class="light-text">{{
                  purchaseOrder.dateTime
                }}</span></span
              >
              <br />
              <span class="bold-text"
                >{{ $t("purchase order number") }}#:
                <span class="light-text">{{
                  purchaseOrder.orderNo
                }}</span></span
              >
            </div>
          </div>
          <!-- ####supplier and customer details#########  -->
          <div class="row">
            <div class="column-half left">
              <button class="button gradients">{{ $t("vendor") }}</button>
            </div>

            <div class="column-half left">
              <button class="button gradients">{{ $t("ship to") }}</button>
            </div>
          </div>
          <div class="row">
            <div class="column-half left">
              <span class="bold-text"
                >{{ $t("supplier name") }}:
                <span class="light-text">{{ supplier.name }}</span></span
              ><br />
              <span class="bold-text"
                >{{ $t("address") }} :
                <span class="light-text">{{ supplier.address }}</span></span
              ><br />
              <span class="bold-text"
                >{{ $t("telephone") }}:
                <span class="light-text">{{ supplier.phone }}</span></span
              ><br />
              <span class="bold-text"
                >{{ $t("email") }}:
                <span class="light-text">{{ supplier.email }}</span></span
              >
            </div>

            <div class="column-half left">
              <span class="bold-text"
                >{{ $t("business name") }}:
                <span class="light-text">{{
                  business.businessName
                }}</span></span
              ><br />
              <span class="bold-text"
                >{{ $t("address") }} :
                <span class="light-text"
                  >{{ business.address.building }} ,{{
                    business.address.city
                  }}
                  ,{{ business.address.country }}</span
                ></span
              ><br />
              <span class="bold-text"
                >{{ $t("telephone") }}:
                <span class="light-text">{{ business.phone }}</span></span
              ><br />
              <span class="bold-text"
                >{{ $t("email") }}:
                <span class="light-text">{{ business.email }}</span></span
              >
            </div>
          </div>
          <!-- ########Shipping terms###### -->
          <div class="row">
            <div class="column right">
              <button class="button gradients">
                <div class="row center">
                  <div class="column-third">
                    <span class="bold-text">{{ $t("shipping terms") }}</span>
                  </div>
                  <div class="column-third">
                    <span class="bold-text">{{ $t("shipping method") }} </span>
                  </div>
                  <div class="column-third">
                    <span class="bold-text">{{ $t("delivery date") }}</span>
                  </div>
                </div>
              </button>
            </div>
          </div>

          <div class="row">
            <div class="column-third center">
              <span class="bolder-text">{{ purchaseOrder.shippingTerms }}</span>
            </div>
            <div class="column-third center">
              <span class="bolder-text">{{
                purchaseOrder.shippingMethod
              }}</span>
            </div>
            <div class="column-third right">
              <span class="bolder-text">{{ purchaseOrder.deliveryDate }} </span>
            </div>
          </div>

          <!-- ########Product list###### -->
          <div class="row">
            <button class="button gradients">
              <div class="row center">
                <div class="column-extra-large">
                  <div class="column-twenty-percent">
                    <span class="bold-text">{{ $t("item") }}#</span>
                  </div>
                  <div class="column-forty-percent">
                    <span class="bold-text">{{ $t("description") }}</span>
                  </div>
                  <div class="column-twenty-percent">
                    <span class="bold-text">{{ $t("quantity") }}</span>
                  </div>
                  <div class="column-twenty-percent">
                    <span class="bold-text">{{ $t("unit price") }}</span>
                  </div>
                </div>
                <div class="column-small" style="padding-top: 20px">
                  <span class="bold-text">{{ $t("total") }}</span>
                </div>
              </div>
            </button>
          </div>

          <div v-for="(item, i) in orderedProducts" :key="i">
            <div class="row center">
              <div class="column-extra-large">
                <div class="column-twenty-percent">
                  <span class="bold-text">{{ item.name }} </span>
                  <br />
                  <span class="light-text" style="font-size: 12px"
                    >{{ item.barcodeNo }}
                  </span>
                </div>
                <div class="column-forty-percent">
                  <span class="">{{ item.description }} </span>
                </div>
                <div class="column-twenty-percent">
                  <span class="bold-text">{{ item.quantity }} </span>
                </div>
                <div class="column-twenty-percent">
                  <span class="bold-text"
                    >{{ currencyFormat(item.unitPrice) }}
                  </span>
                </div>
              </div>
              <div class="column-small" style="padding-top: 20px">
                <span class="bold-text">{{ currencyFormat(item.total) }}</span>
              </div>
            </div>
            <hr class="grey-hr" />
          </div>

          <!-- ########Shipping terms###### -->
          <div class="row">
            <div class="column right">
              <div class="row center">
                <div class="column-half">
                  <span class="bold-text left" style="font-size: 12px"
                    >{{ $t("comments /special instructions") }}
                  </span>
                  <textarea
                    class="left"
                    name=""
                    id=""
                    v-model="purchaseOrder.comments"
                    rows="10"
                    style="width: 100%; border: 1px solid; border-radius: 3px"
                  ></textarea>
                </div>
                <div class="column-half">
                  <div class="row left" style="margin-top: 15px">
                    <div class="column-forty-percent left">
                      <span class="bold-text">{{ $t("subTotal") }}</span>
                    </div>
                    <div class="column-sixty-percent right">
                      <span class=""
                        >{{ currencyFormat(purchaseOrder.subtotal) }}
                      </span>
                    </div>
                  </div>
                  <hr class="grey-hr" />
                  <div class="row center" style="margin-top: 15px">
                    <div class="column-forty-percent left">
                      <span class="bold-text">{{ $t("sales tax") }}</span>
                    </div>

                    <div class="column-sixty-percent right">
                      <span class=""
                        >{{ currencyFormat(purchaseOrder.salesTax) }}
                      </span>
                    </div>
                  </div>
                  <hr class="grey-hr" />
                  <div class="row" style="margin-top: 15px">
                    <div class="column-forty-percent left left">
                      <span class="bold-text" style="margin-left: 0px"
                        >{{ $t("shipping and handling") }}
                      </span>
                    </div>

                    <div class="column-sixty-percent right">
                      <span class=""
                        >{{ currencyFormat(purchaseOrder.shippingCosts) }}
                      </span>
                    </div>
                  </div>
                  <hr class="grey-hr" />
                  <div class="row center" style="margin-top: 15px">
                    <div class="column-forty-percent left">
                      <span class="bold-text">{{ $t("other costs") }}</span>
                    </div>
                    <div class="column-sixty-percent right">
                      <span class=""
                        >{{ currencyFormat(purchaseOrder.otherCosts) }}
                      </span>
                    </div>
                  </div>
                  <hr class="grey-hr" />
                  <div class="row center" style="margin-top: 15px">
                    <div class="column-forty-percent left">
                      <span class="bold-text">{{ $t("discount") }}</span>
                    </div>

                    <div class="column-sixty-percent right">
                      <span class=""
                        >{{ currencyFormat(purchaseOrder.discount) }}
                      </span>
                    </div>
                  </div>
                  <hr class="grey-hr" />
                  <div class="row center" style="margin-top: 15px">
                    <div class="column-forty-percent left">
                      <span class="bold-text">{{ $t("grand total") }}</span>
                    </div>

                    <div class="column-sixty-percent right">
                      <span class="bold-text"
                        >{{ currencyFormat(purchaseOrder.total) }}
                      </span>
                    </div>
                  </div>
                  <hr class="grey-hr" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card>
    <v-snackbar
      v-model="snackInfo"
      bottom
      right
      color="info lighten-5 info--text text--darken-3"
      :timeout="4000"
    >
      <span>{{ $t("printing...") }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="info darken-3"
          @click="snackInfo = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import db from "@/plugins/fb";

import { printer } from "@/plugins/functions";
export default {
  props: ["supplier", "business", "orderedProducts", "purchaseOrder"],
  data: () => ({
    snackInfo: false,
    saveLoading: false,

    snackbarUpload: false,
  }),
  methods: {
    printer,
    //attach the selected currency to money
    currencyFormat(val) {
      return this.$store.state.currency.symbol + " " + Number(val).toFixed(2);
    },
    goBack() {
      this.$emit("purchaseDialog");
    },
    listPurchaseOrders() {
      this.$router.push({ name: "ViewPurchaseOrders" });
    },
    uploadData() {
      this.saveLoading = true;

      db.collection("purchaseOrders")
        .add(this.purchaseOrder)
        .then(() => {
          this.loading = false;
          this.snackbarUpload = true;
          setTimeout(() => this.listPurchaseOrders(), 2000);
        });
    },

    printPage() {
      this.snackInfo = true;
      this.printLoading = false;
      this.printer("print", "PurchaseOrder");
    },
  },
};
</script>
<style scoped>
.white-hr {
  border: 1px dashed white;
  background-color: white;
}
.black-hr {
  border-top: 1px solid #000000;
  height: 1px;
  border-radius: 5px;
}
.grey-hr {
  border-top: 1px solid #cecece;
  height: 1px;
  border-radius: 5px;
}
.black-background {
  background-color: #000000;
}
.gradients {
  background-image: linear-gradient(to bottom right, #0579a3, #005270);

  /* background-image: linear-gradient(to bottom right, #072033,#103c5e); */
}
.white-text {
  color: white;
}

.center {
  text-align-last: center;
}

.center {
  text-align-last: center;
}
.left {
  text-align-last: left;
}

.right {
  text-align-last: right;
}
/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}
.title {
  color: #ed6d1e;
}

.body {
  background-color: #e0e0e0;
}
.body .container {
  background: black (0, 0, 0); /* Fallback color */
  color: grey (126, 126, 126);
  font-size: 15px;
  background-color: white;
}
.bold-text {
  font-weight: bolder;
}
.light-text {
  font-weight: lighter;
}
.column {
  float: left;
  width: 100%;
  padding: 10px;
}

.column-half {
  float: left;
  width: 50%;
  padding: 10px;
}

.column-third {
  float: left;
  width: 33.33%;
  padding: 10px;
}

.column-two-third {
  float: left;
  width: 66.66%;
  padding: 10px;
}

.column-quarter {
  float: left;
  width: 25%;
  padding: 10px;
}

.column-three-quarter {
  float: left;
  width: 75%;
  padding: 10px;
}
.column-small {
  float: left;
  width: 15%;
  padding: 10px;
}
.column-extra-large {
  float: left;
  width: 85%;
  padding: 10px;
}
.column-forty-percent {
  float: left;
  width: 40%;
  padding: 10px;
}
.column-sixty-percent {
  float: left;
  width: 60%;
  padding: 10px;
}
.column-twenty-percent {
  float: left;
  width: 20%;
  padding: 10px;
}
.button {
  background-color: #4caf50; /* Green */
  border: none;
  color: white;
  padding: 5px 15px;
  text-align: left;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;

  width: 100%;
}
</style>
